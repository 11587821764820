import React from "react";
import SEO from './../components/SEO';

const NotFoundPage = () => (
  <>
    <SEO title="404" />
    <h1>Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
);

export default NotFoundPage;
